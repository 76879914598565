import React from "react";
import styles from "./styles.module.scss";

interface GridProps {
  style?: React.CSSProperties;
  maxCols?: number;
  children?: React.ReactNode;
}

interface ColProps {
  half?: boolean;
}

const classNames = {
  3: styles.rowCols3,
};

const Row: React.FC<GridProps> = ({ children, style, maxCols }) => {
  return (
    <div
      className={`${styles.row} ${maxCols === 3 ? classNames[maxCols] : null}`}
      style={style}
    >
      {children}
    </div>
  );
};

const Col: React.FC<GridProps & ColProps> = ({ children, style, half }) => {
  return (
    <div className={half ? styles.half : styles.col} style={style}>
      {children}
    </div>
  );
};

export { Row, Col };
