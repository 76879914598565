import styles from './styles.module.scss';

interface LabelProps {
  className?: string;
  pointer?: boolean;
  htmlFor?: string;
  color?: string;
  children?: React.ReactNode;
}

const Label: React.FC<LabelProps> = ({
  children,
  className,
  pointer,
  htmlFor,
  color
}) => {
  return (
    <label
      htmlFor={htmlFor}
      className={`${styles.label} ${
        pointer ? styles.pointer : ''
      } ${className}`}
      style={{ color }}
    >
      {children}
    </label>
  );
};

export { Label };
