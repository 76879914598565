import logoUrl from "../../assets/logo.jpg";
import styles from "./styles.module.scss";

interface LogoProps {
  size?: number;
}

const Logo: React.FC<LogoProps> = ({ size }) => {
  return (
    <img
      src={logoUrl}
      className={styles.logo}
      style={{ width: `${size}px` }}
      alt="Media Parada"
    />
  );
};

export { Logo };
