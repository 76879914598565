import styles from './styles.module.scss';

interface TitleProps {
  className?: string;
  children?: React.ReactNode;
}

const Title: React.FC<TitleProps> = ({ className, children }) => {
  return <div className={`${styles.title} ${className}`}>{children}</div>;
};

export { Title };
