import React from "react";
import styles from "./styles.module.scss";

interface AlertProps {
  type?: "error" | "success";
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const Alert: React.FC<AlertProps> = ({ type, style, children }) => {
  const alertTypeClass = {
    error: styles.error,
    success: styles.success,
  };

  return (
    <div
      className={`${styles.alert} ${type && alertTypeClass[type]}`}
      style={style}
    >
      {children}
    </div>
  );
};

export { Alert };
