import { getApp, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

initializeApp({
  apiKey: "AIzaSyD6nqJF-0CKk-yPf2bC6m97eoH8XZel-5I",
  authDomain: "media-parada.firebaseapp.com",
  projectId: "media-parada",
  storageBucket: "media-parada.appspot.com",
  messagingSenderId: "580332884625",
  appId: "1:580332884625:web:c1476d0c4d997579d0e530",
  measurementId: "G-SLNEE3VQ5N",
});

const auth = getAuth();
const firestore = getFirestore();
const functions = getFunctions(getApp(), "europe-west1");
// connectFunctionsEmulator(functions, "localhost", 5001);

export { auth, firestore, functions };
