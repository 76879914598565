import ReactModal from "react-modal";
import styles from "./styles.module.scss";

interface ModalProps {
  isOpen: boolean;
  title?: string;
  maxWidth?: string;
  onClickClose?: () => void;
  contentStyle?: React.CSSProperties;
  showCloseButton?: boolean;
  onAfterClose?: () => void;
  children?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  title,
  onClickClose,
  onAfterClose,
  children,
  maxWidth,
  contentStyle,
  showCloseButton = true,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      className={styles.modalDialog}
      overlayClassName={styles.modalOverlay}
      bodyOpenClassName={styles.modalOpened}
      ariaHideApp={false}
      closeTimeoutMS={300}
      onAfterClose={onAfterClose}
    >
      <div
        className={styles.modalContent}
        style={{ maxWidth, ...contentStyle }}
      >
        <div className={styles.modalHeader}>
          <div className={styles.title}>{title}</div>
          {showCloseButton && (
            <div className={styles.closeButton} onClick={onClickClose}>
              ×
            </div>
          )}
        </div>

        <div className={styles.modalBody}>{children}</div>
      </div>
    </ReactModal>
  );
};

export { Modal };
