import { lazy } from "react";
import { DashboardContainer } from "./containers";
import { useAuth } from "./providers";

const LoadingPage = lazy(() => import("./pages/LoadingPage"));
const LoginPage = lazy(() => import("./pages/LoginPage"));

const App: React.FC = () => {
  const { user, isLoading } = useAuth();

  return isLoading ? (
    <LoadingPage />
  ) : user ? (
    <DashboardContainer />
  ) : (
    <LoginPage />
  );
};

export { App };
