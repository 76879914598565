import { ReactElement, lazy, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ReactComponent as CategoriesIcon } from "../../assets/icons/grid.svg";
import { ReactComponent as NewsIcon } from "../../assets/icons/news.svg";
import { ReactComponent as TrophyIcon } from "../../assets/icons/trophy.svg";
import { ReactComponent as UsersIcon } from "../../assets/icons/users.svg";
import { Navbar, Sidebar } from "../../components";
import { Role, useAuth } from "../../providers";
import styles from "./styles.module.scss";

const UsersPage = lazy(() => import("../../pages/Users/UsersPage"));
const CategoriesPage = lazy(() => import("../../pages/CategoriesPage"));
const CompetitionsPage = lazy(
  () => import("../../pages/Competitions/CompetitionsPage")
);
const CompetitionAction = lazy(
  () => import("../../pages/Competitions/CompetitionAction")
);
const CreateUser = lazy(() => import("../../pages/Users/CreateUser"));
const NewsPage = lazy(() => import("../../pages/NewsPage"));
const NewsActionPage = lazy(() => import("../../pages/NewsPage/NewsAction"));

const NotFoundPage = lazy(() => import("../../pages/NotFoundPage"));

export interface Path {
  path: string;
  name: string;
  exact?: boolean;
  icon?: JSX.Element;
  element: ReactElement;
  admin?: boolean;
  hidden?: boolean;
}

const paths: { [key: string]: Path } = {
  users: {
    path: "/users",
    name: "Usuarios",
    icon: <UsersIcon className={styles.icon} />,
    admin: true,
    element: <UsersPage />,
  },
  createUser: {
    path: "/users/create",
    name: "Crear usuario",
    icon: <UsersIcon className={styles.icon} />,
    hidden: true,
    admin: true,
    element: <CreateUser />,
  },
  editUser: {
    path: "/users/:id",
    name: "Editar usuario",
    icon: <UsersIcon className={styles.icon} />,
    hidden: true,
    admin: true,
    element: <CreateUser />,
  },
  categories: {
    path: "/categories",
    name: "Disciplinas",
    icon: <CategoriesIcon className={styles.icon} />,
    element: <CategoriesPage />,
    admin: true,
  },
  competitions: {
    path: "/competitions",
    name: "Competiciones",
    icon: <TrophyIcon className={styles.icon} />,
    element: <CompetitionsPage />,
  },
  createCompetition: {
    path: "/competitions/create",
    name: "Crear competición",
    icon: <TrophyIcon className={styles.icon} />,
    hidden: true,
    element: <CompetitionAction />,
    admin: true,
  },
  editCompetition: {
    path: "/competitions/:id",
    name: "Editar competición",
    icon: <TrophyIcon className={styles.icon} />,
    hidden: true,
    element: <CompetitionAction />,
  },
  news: {
    path: "/news",
    name: "Noticias",
    admin: true,
    icon: <NewsIcon className={styles.icon} />,
    element: <NewsPage />,
  },
  createNews: {
    path: "/news/create",
    name: "Crear noticia",
    admin: true,
    hidden: true,
    icon: <NewsIcon className={styles.icon} />,
    element: <NewsActionPage />,
  },
  updateNews: {
    path: "/news/:id",
    name: "Editar noticia",
    admin: true,
    hidden: true,
    icon: <NewsIcon className={styles.icon} />,
    element: <NewsActionPage />,
  },
};

const DashboardContainer: React.FC = () => {
  const location = useLocation();
  const [sidebarOpened, setSidebarOpened] = useState(false);

  const { role } = useAuth();

  useEffect(() => {
    setSidebarOpened(false);
  }, [location]);

  return (
    <div className={styles.dashboardContainer}>
      <Navbar toggleSidebar={setSidebarOpened} />
      <Sidebar
        paths={paths}
        isOpened={sidebarOpened}
        toggleSidebar={setSidebarOpened}
      />

      <div className={styles.content}>
        <Routes>
          <Route
            index
            element={
              <Navigate
                to={role === Role.ADMIN ? "/users" : "/competitions"}
                replace
              />
            }
          />
          {Object.values(paths)
            .filter((path) => (path.admin ? role === Role.ADMIN : true))
            .map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </div>
  );
};

export { DashboardContainer };
