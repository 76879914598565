import styles from './styles.module.scss';

interface ContainerProps {
  className?: string;
  noPadding?: boolean;
  children?: React.ReactNode;
}

const Container: React.FC<ContainerProps> = ({
  children,
  noPadding,
  className
}) => {
  return (
    <div
      className={`${styles.container} ${className}`}
      style={noPadding ? { padding: 0 } : {}}
    >
      {children}
    </div>
  );
};

export { Container };
