import styles from './styles.module.scss';

interface FormGroupProps {
  className?: string;
  children?: React.ReactNode;
}

const FormGroup: React.FC<FormGroupProps> = ({ className, children }) => {
  return <div className={`${styles.formGroup} ${className}`}>{children}</div>;
};

export { FormGroup };
