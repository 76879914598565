import styles from "./styles.module.scss";

interface SearchBarProps {
  placeholder?: string;
  onChangeText?: (text: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onChangeText, placeholder }) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    onChangeText && onChangeText(val);
  };

  return (
    <input
      type="text"
      autoComplete="off"
      name="search"
      className={styles.searchBar}
      placeholder={placeholder}
      onChange={handleOnChange}
    />
  );
};

export { SearchBar };
