import { Logo } from "../";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg";
import styles from "./styles.module.scss";

interface NavbarProps {
  toggleSidebar: (isOpened: boolean) => void;
}

const Navbar: React.FC<NavbarProps> = ({ toggleSidebar }) => {
  return (
    <div className={styles.navbar}>
      <div className={styles.iconButton} onClick={() => toggleSidebar(true)}>
        <MenuIcon className={styles.icon} />
      </div>

      <div className={styles.logo}>
        <Logo size={150} />
      </div>

      {/* <NavLink to="/settings" className={styles.profile}>
        <Avatar
          photo={user?.photo}
          alt={user?.username || ''}
          blurHash={user?.photoBlurHash}
          size={42}
        />
        <div className={styles.userInfo}>
          <div className={styles.userName}>
            {user?.name} {user?.surname}
          </div>
          <div className={styles.userUsername}>@{user?.username}</div>
        </div>
  </NavLink> */}
    </div>
  );
};

export { Navbar };
