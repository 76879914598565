import styles from "./styles.module.scss";
import { Loader } from "..";

interface ButtonProps {
  isLoading?: boolean;
  disabled?: boolean;
  small?: boolean;
  block?: boolean;
  style?: React.CSSProperties;
  type?: "default" | "success" | "danger" | "light" | "danger-link";
  submit?: boolean;
  onClick?: () => void;
  className?: string;
  children?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  isLoading,
  disabled,
  small,
  children,
  block,
  onClick,
  style,
  submit,
  className,
  type = "default",
}) => {
  return (
    <button
      className={`${styles.button} ${block && styles.block} ${
        small && styles.small
      } ${type && styles[type]} ${className}`}
      onClick={onClick}
      style={style}
      disabled={disabled || isLoading}
      type={submit ? "submit" : "button"}
    >
      {isLoading ? (
        <div className={styles.loader}>
          <Loader size="small" color="#FFF" />
        </div>
      ) : null}
      <div className={isLoading ? styles.isLoadingText : styles.buttonContent}>
        {children}
      </div>
    </button>
  );
};

export { Button };
