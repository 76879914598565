import { useEffect } from "react";
import { Loader, Logo } from "../../components";
import styles from "./styles.module.scss";

const LoadingPage: React.FC = () => {
  useEffect(() => {
    document.body.classList.add(styles.bodyBackground);

    return () => {
      document.body.classList.remove(styles.bodyBackground);
    };
  }, []);

  return (
    <div className={styles.loadingContainer}>
      <Logo size={200} />
      <Loader className={styles.loader} />
    </div>
  );
};

export default LoadingPage;
