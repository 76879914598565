import { signOut } from "firebase/auth";
import { NavLink } from "react-router-dom";
import { Path } from "../../containers";
import { auth } from "../../firebase";
import { Role, useAuth } from "../../providers";
import { Logo } from "../Logo";
import styles from "./styles.module.scss";

interface SidebarProps {
  paths: { [key: string]: Path };
  isOpened: boolean;
  toggleSidebar: (isOpened: boolean) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  paths,
  isOpened,
  toggleSidebar,
}) => {
  const { role } = useAuth();

  const logout = () => {
    signOut(auth);
  };

  return (
    <div className={`${styles.sidebar} ${isOpened && styles.opened}`}>
      <div className={styles.sidebarContent}>
        <NavLink to="/" className={styles.logoLink}>
          <Logo size={200} />
        </NavLink>

        <div className={styles.navbar}>
          {Object.values(paths)
            .filter((item) => !item.hidden)
            .filter((path) => (path.admin ? role === Role.ADMIN : true))
            .map((route, index) => (
              <NavLink
                key={index}
                to={route.path}
                className={({ isActive }) =>
                  styles.navItem + (isActive ? ` ${styles.active}` : "")
                }
                end={route.exact}
              >
                {route.icon}
                {route.name}
              </NavLink>
            ))}
        </div>

        <div
          className={`${styles.navItem} ${styles.logoutItem}`}
          onClick={logout}
        >
          Cerrar sesión
        </div>
      </div>
      <div className={styles.backdrop} onClick={() => toggleSidebar(false)} />
    </div>
  );
};

export { Sidebar };
