import { forwardRef } from "react";
import { Label } from "../Label";
import styles from "./styles.module.scss";

interface SelectProps extends React.HTMLProps<HTMLSelectElement> {
  label?: string;
  options?: {
    label: string;
    value: string;
    disabled?: boolean;
  }[];
  hasError?: boolean;
}

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ options, label, hasError, ...otherProps }, ref) => {
    return (
      <>
        {label ? (
          <Label color={hasError ? "#F44336" : ""}>{label}</Label>
        ) : null}
        <select
          ref={ref}
          className={`${styles.select} ${hasError && styles.hasError}`}
          defaultValue={otherProps.defaultValue}
          {...otherProps}
        >
          {options?.map((option) => (
            <option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </option>
          ))}
        </select>
      </>
    );
  }
);

export { Select };
