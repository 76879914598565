import { User, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { auth, firestore } from "../firebase";

enum Role {
  NONE = "none",
  ADMIN = "admin",
  MANAGER = "manager",
}

interface UserContextProps {
  user: User | null;
  role: Role;
  isLoading: boolean;
}

interface UserProviderProps {
  children?: React.ReactNode;
}

const UserContext = createContext<UserContextProps>({
  user: null,
  role: Role.NONE,
  isLoading: false,
});

const useAuth = () => useContext(UserContext);

const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [role, setRole] = useState<Role>(Role.NONE);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const docRef = doc(firestore, "permissions", user.uid);
        const docSnap = await getDoc(docRef);

        setRole(docSnap.get("role"));
      }

      setUser(user);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider value={{ user, role, isLoading }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, Role, UserProvider, useAuth };
